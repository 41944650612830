import { z } from 'zod';

export const filterSchema = z.object({
	sort_by: z.string().optional(),
	// eidDelivery: z.array(z.string()).optional(),
	size: z.array(z.string()).optional(),
	discount: z.array(z.string()).optional(),
	sale_percentage: z.string().optional(),
	brand: z.array(z.string()).optional(),
	price: z
		.object({
			from: z.number().min(0, { message: '"From" value can not be negative' }),
			to: z.number().min(0, { message: '"To" value can not be negative' }),
		})
		.optional()
		.refine(
			(price) => {
				if (!price) return true;
				return price.from <= price.to;
			},
			{
				message: '"From" must be less than "To"',
				path: ['from'],
			},
		),
	node_id: z.array(z.string()).optional(),
	delivery_days: z.array(z.string()).optional(),
	tags: z.array(z.string()).optional(),
	attrs: z.array(z.string()).optional(),
	period: z.string().optional(),
});

export const entityType = z.string();

export const linkNodeSchema = z.object({
	name: z.string(),
	href: z.string().min(1, 'Link is required'),
	id: z.number(),
	entity: entityType,
	subtitle: z.string().optional(),
	handle: z.string().optional(),
	image: z.string().optional(),
	fallback_image: z.string().optional(),
	max_sale: z.number().optional(),
	product_count: z.number().optional(),
	slotted: z.boolean().optional(),
	logo: z.string().optional(),
});

const menuItemSchema = linkNodeSchema.extend({
	children: linkNodeSchema.array().optional(),
});

export const headerFormSchema = z.object({
	logo: z.object({
		url: z.string(),
		preview: z.string().optional(),
	}),
	menubar: z.array(menuItemSchema),
});

export const productCard = z.object({
	shopify_id: z.number(),
	title: z.string(),
	handle: z.string(),
	brand: z.string(),
	partner: z.string(),
	category: z.string(),
	status: z.string(),
	valhalla_score: z.number(),
	description: z.string(),
	total_inventory_quantity: z.number(),
	external_product_id: z.string(),
	price: z.number(),
	compare_at_price: z.number(),
	tags: z.array(z.string()),
	image: z.object({
		shopify_id: z.number(),
		alt: z.string(),
		src: z.string(),
		width: z.number(),
		height: z.number(),
	}),
	variants: z.array(
		z.object({
			shopify_id: z.number(),
			price: z.string(),
			compare_at_price: z.string(),
			inventory_quantity: z.number(),
			reseller_price: z.string().optional(),
			weight_unit: z.string(),
			weight: z.number(),
			sku: z.string(),
			title: z.string(),
			stock_status: z.string(),
		}),
	),
	options: z.array(
		z.object({
			values: z.array(z.string()),
			name: z.string(),
		}),
	),
	media: z
		.array(
			z.object({
				shopify_id: z.number(),
				alt: z.string(),
				src: z.string(),
				width: z.number(),
				height: z.number(),
			}),
		)
		.nullable(),
	size_chart_id: z.number().optional(),
	drop_handle: z.string(),
	size_chart_name: z.string().optional(),
	stock_status: z.string().optional(),
});

export const dynamicSettingsBrands = z.object({
	id: z.number(),
	name: z.string(),
	checked: z.boolean().optional(),
});

export const dynamicInputSchema = z.object({
	selectedCategory: z.number().optional(),
	selectedBrands: z.array(z.number()),
	selectedPin: z.number().optional(),
	selectedDrop: z.number().optional(),
	filters: filterSchema.optional(),
	ranking: z.string(),
	limit: z.number().optional(),
	dateRange: z
		.object({
			from: z.string(),
			to: z.string(),
		})
		.optional(),
	timePeriod: z.string().optional(),
});

export const staticInputFormSchema = linkNodeSchema
	.partial()
	.required({ id: true })
	.extend({
		image_preview: z.string().optional(),
	});

export const staticInputSchema = z.union([
	z.object({
		ids: z
			.array(z.number())
			.min(1, { message: 'At least one Item is required' }),
	}),
	z.object({
		items: z
			.array(staticInputFormSchema)
			.min(1, { message: 'At least one Item is required' }),
	}),
]);

export const entityFormSchema = z.discriminatedUnion('method', [
	z.object({
		method: z.literal('dynamic'),
		title: z.string().min(1, 'Title is required'),
		subtitle: z.string().max(50).optional(),
		entity: z.string(),
		input: dynamicInputSchema,
		alignment: z.enum(['left', 'center']),
	}),
	z.object({
		method: z.literal('static'),
		title: z.string().min(1, 'Title is required'),
		subtitle: z.string().max(50).optional(),
		entity: z.string(),
		input: staticInputSchema,
		alignment: z.enum(['left', 'center']),
	}),
]);

export const timerSchema = z
	.object({
		startDateTime: z.string(),
		endDateTime: z.string(),
		enabled: z.boolean(),
	})
	.refine(
		(data) => {
			const startDateTime = new Date(data.startDateTime);
			const endDateTime = new Date(data.endDateTime);
			return startDateTime.getTime() <= endDateTime.getTime();
		},
		{
			message: 'Start date time should be less than end date time',
			path: ['startDateTime'],
		},
	);

export const productFormSchema = z.discriminatedUnion('method', [
	z.object({
		entity: z.literal('products'),
		method: z.literal('dynamic'),
		title: z.string().min(1, 'Title is required'),
		alignment: z.enum(['left', 'center']),
		input: dynamicInputSchema,
		timer: timerSchema.optional(),
	}),
	z.object({
		entity: z.literal('products'),
		method: z.literal('static'),
		title: z.string().min(1, 'Title is required'),
		alignment: z.enum(['left', 'center']),
		input: staticInputSchema,
		timer: timerSchema.optional(),
	}),
]);

export const entityXProductsFormSchema = z.discriminatedUnion('method', [
	z.object({
		method: z.literal('dynamic'),
		title: z.string().min(1, 'Title is required'),
		subtitle: z.string().max(50).optional(),
		entity: z.string(),
		input: dynamicInputSchema,
	}),
	z.object({
		method: z.literal('static'),
		title: z.string().min(1, 'Title is required'),
		subtitle: z.string().max(50).optional(),
		entity: z.string(),
		input: staticInputSchema,
	}),
]);

export const heroBannerItemSchema = z.object({
	mobile_url: z.string(),
	mobile_preview: z.string().optional(),
	desktop_url: z.string(),
	desktop_preview: z.string().optional(),
	link: linkNodeSchema,
});
export const heroBannerFormSchema = z.object({
	method: z.literal('static'),
	entity: z.literal('hero-banner'),
	output: z.array(heroBannerItemSchema).min(1),
});

export const miniBannerFormSchema = z.object({
	method: z.literal('static'),
	entity: z.literal('mini-banner'),
	output: heroBannerItemSchema,
});

export const HERO_BANNER_LIMIT = 6;
export const CMS_ENTITIES = z.enum(['categories', 'drop', 'quick-adds']);

export const octaneFooterSchema = z.object({
	email: z.string().email().optional(),
	phone: z.string().optional(),
	socialLinks: z
		.object({
			facebook: z.string().url().or(z.string().length(0)).optional(),
			instagram: z.string().url().or(z.string().length(0)).optional(),
			whatsapp: z.string().url().or(z.string().length(0)).optional(),
			youtube: z.string().url().or(z.string().length(0)).optional(),
			tiktok: z.string().url().or(z.string().length(0)).optional(),
		})
		.optional(),
	description: z.string().optional(),
});
