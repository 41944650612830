import revive_payload_client_zGb8HTXm7a from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zHJcORak2E from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BU9pvR1tVx from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TH76snyH9r from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.4_rollup@4.17.1_vite@5.2.10_@types+node@20.11.24_s_aa736e97d34201c03d3465ecb4e586d1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_AQJNkTEUuc from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_6wyOJU0hF7 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Z8dpSEzNEG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.6.3_vue@3.4.21_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/online-store/.nuxt/components.plugin.mjs";
import prefetch_client_Wlhya0EdQu from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Fv9MAOeyYV from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.10.3_@parcel+watcher@2.4.1_@type_9418dda926e7c219639f609cc9d19fbc/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_geaxjDQbCD from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2_211990055de5fa5809fdb80961a63475/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_AEGHy5jHTG from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2_211990055de5fa5809fdb80961a63475/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_9bGhm5UXj8 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@types+n_da6ab26192ef543425b2a2f1be37c534/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_YzMtnSdXVv from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.17.1_vite@5.2.10_@types+n_da6ab26192ef543425b2a2f1be37c534/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_KAIh3OLU6I from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_io_be563e76122b631852e7a05b81348317/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_events_client_dLOKnLsuLH from "/app/apps/online-store/plugins/01.events.client.ts";
import _02_cathode_client_Qbf8UvtDFV from "/app/apps/online-store/plugins/02.cathode.client.ts";
import axios_QMFgzss1s4 from "/app/apps/online-store/plugins/axios.ts";
import disable_logs_PLbUQJb9qQ from "/app/apps/online-store/plugins/disable-logs.ts";
import error_handler_kEP5FliEXj from "/app/apps/online-store/plugins/error-handler.ts";
import sentry_client_shVUlIjFLk from "/app/apps/online-store/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/apps/online-store/plugins/vue-query.ts";
export default [
  revive_payload_client_zGb8HTXm7a,
  unhead_zHJcORak2E,
  router_BU9pvR1tVx,
  _0_siteConfig_TH76snyH9r,
  payload_client_AQJNkTEUuc,
  check_outdated_build_client_6wyOJU0hF7,
  plugin_vue3_Z8dpSEzNEG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Wlhya0EdQu,
  plugin_Fv9MAOeyYV,
  titles_geaxjDQbCD,
  defaults_AEGHy5jHTG,
  siteConfig_9bGhm5UXj8,
  inferSeoMetaPlugin_YzMtnSdXVv,
  chunk_reload_client_KAIh3OLU6I,
  _01_events_client_dLOKnLsuLH,
  _02_cathode_client_Qbf8UvtDFV,
  axios_QMFgzss1s4,
  disable_logs_PLbUQJb9qQ,
  error_handler_kEP5FliEXj,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe
]